export const palette: { [key: string]: string } = {
    c1: '#0d0d3f',
    c2: '#676793',
    c3: '#fff',
    c4: '#2e71f0',
    c5: '#e65069',
    c6: '#0abf64',
    c7: '#667c99',
    c8: '#bdc8d9',
    c9: '#e5e9ed',
    c10: '#e3e7ee',
    c11: '#c96866',
    c12: '#f8fafc',
    c13: '#fcfcfc',
    c14: '#e5eaee',
    c15: '#e1e7f2',
    c16: '#000',
    c17: '#e6efff',
    c18: '#3e80ff',
    c19: '#f5f5f5',
    c20: '#26374c',
    c21: '#9da3b7',
    c22: '#F58E23',
};
