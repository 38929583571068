import merge from 'lodash/merge'
import { IBaseInputLikeStyledTheme, IInputLikeStyledTheme } from 'merchants/types/IThemeStyles';
import { baseTheme } from 'merchants/identance';
import { palette } from './palette';

const defaultGlobalBaseInputLikeStyles: IBaseInputLikeStyledTheme = {
  backgroundColor: palette.c13,
  borderColor: palette.c8,

  disabled: {
    borderColor: palette.c8,
    backgroundColor: 'transparent',
  },
};

const defaultGlobalInputLikeStyles: IInputLikeStyledTheme = {
  ...defaultGlobalBaseInputLikeStyles,
  borderRadius: '4px',
  borderWidth: '1px',
  color: palette.c20,
  boxShadow: 'none',

  focus: {
    backgroundColor: palette.c3,
    borderColor: palette.c4,
  },

  disabled: {
    ...defaultGlobalBaseInputLikeStyles.disabled,
    color: palette.c7,
  },

  error: {
    backgroundColor: palette.c3,
    borderColor: palette.c5,
  },
};

export const theme = {
  layout: {
    backgroundColor: palette.c3
  },
  buttons: {
    base: {
      borderRadius: '10px',
      backgroundColor: palette.c3,
      border: `1.5px solid ${palette.c4}`,
      color: palette.c4,
      hover: {
        color: palette.c4,
        backgroundColor: palette.c17,
      },
      disabled: {
        opacity: '1',
        backgroundColor: palette.c19,
        color: palette.c2,
        border: 'none'
      },
    },
    primary: {
      backgroundColor: palette.c4,
      borderRadius: '10px',
      border: `1.5px solid ${palette.c4}`,

      hover: {
        backgroundColor: palette.c18,
        color: palette.c3,
      }
    },
    resubmit: {
      triggerColor: palette.c3
    }
  },
  links: {
    base: {
      color: palette.c4,
      hover: {
        color: palette.c4,
      },
    },
    exit: {
      backgroundColor: palette.c3,
      color: palette.c4,
      hover: {
        color: palette.c4,
        backgroundColor: palette.c17,
      }
    },
    nav: {
      borderRadius: '10px',
      borderColor: palette.c4,
      borderWidth: '1.5px',
      backgroundColor: palette.c4,
      hover: {
        backgroundColor: palette.c3,
        color: palette.c4
      }
    },
  },
  forms: {
    dropdown: {
      ...defaultGlobalInputLikeStyles
    },
    input: {
      ...defaultGlobalInputLikeStyles
    },
    radio: {
      ...defaultGlobalBaseInputLikeStyles,
      borderWidth: '1px',

      checked: {
        backgroundColor: palette.c13,
        borderColor: palette.c8,
        backgroundColorAfter: palette.c4,
      },
    },
    textarea: {
      ...defaultGlobalInputLikeStyles
    },
    tumbler: {
      boxShadow: '0 1px 8px 0 #e5e5e5',
      borderWidth: '1px',
      borderColor: palette.c1,
      backgroundColor: palette.c1,

      checked: {
        borderColor: palette.c4,
        backgroundColor:palette.c4,

      }
    }
  },
  text: {
    error: {
      paddingTop: '2px',
      paddingLeft: '0px'
    },
    h1: {
      color: palette.c1,
    },
    h3: {
      color: palette.c2,
    },
    h4: {
      color: palette.c1,
    },
  },
  components: {
    appendControl: {
      color: palette.c6
    },
    breadcrumbs: {
      color: palette.c16,
      darkArrow: true
    },
    divider: {
      backgroundColor: palette.c3,
    },
    feedback: {
      textColor: palette.c1
    },
    modal: {
      colorCloseButton: palette.c1,
    },
    navigation: {
      backgroundColor: palette.c3,
      boxShadow: '0 2px 17px 0 #e5e5e5'
    },
    previewArea: {
      borderRadius: '8px',
    },
    progress: {
      backgroundColor: palette.c9,
      backgroundColorAfter: palette.c4,
      tagColor: palette.c1,
      tagFontWeight: 'normal'
    },
    slider: {
      backgroundColorActive: palette.c4,
      backgroundColor: palette.c14
    },
    stagePreview: {
      borderRadius: '8px'
    },
    uploader: {
      borderColorLabel: palette.c10,
      borderRadius: '8px',
      borderRadiusLabel: '12px',
      backgroundColor: palette.c3,
      error: {
        borderColor: palette.c5,
      },
    },
  },
  icons: {
    docs: {
      backSide: {
        fill: palette.c21,
        fill2: palette.c14
      },
      blankStatement: {
        fill: palette.c14
      },
      doc: {
        fill: palette.c14,
        fill2: palette.c1
      },
      electricityBill: {
        fill: palette.c14
      },
      frontSide: {
        fill: palette.c19,
        fill2: palette.c21
      },
      miningActivity: {
        fill: palette.c14
      },
      selfie: {
        fill: palette.c21,
        fill2: palette.c14
      },
      selfieWithNote: {
        fill: palette.c21,
        fill2: palette.c14
      },
      taxes: {
        fill: palette.c14,
        fill2: palette.c1
      },
      tradingActivity: {
        fill: palette.c14
      },
      utilityBill: {
        fill: palette.c14,
        fill2: palette.c10,
        fill3: palette.c1
      },
    },
    markup: {
      exit: {
        fill: palette.c4
      },
      star: {
        def: palette.c8,
        active: palette.c4
      },
    },
    stubs: {
      apiError: {
        fill: palette.c4,
        fill2: palette.c5
      },
      card: {
        fill: palette.c4,
        fill2: palette.c5
      },
      checkEmail: {
        fill: palette.c4,
        fill2: palette.c22
      },
      clock: {
        fill: palette.c4,
        fill2: palette.c5
      },
      corporate: {
        fill: palette.c4,
        fill2: palette.c4
      },
      error: {
        fill: palette.c4,
        fill2: palette.c5
      },
      ok: {
        fill: palette.c4,
        fill2: palette.c6
      },
      notice: {
        fill: palette.c4,
        fill2: palette.c22
      },
      qrConnected: {
        fill: palette.c4,
        fill2: palette.c6
      },
      qrSubmitted: {
        fill: palette.c4,
        fill2: palette.c6
      },
      rejected: {
        fill: palette.c4,
        fill2: palette.c5
      },
      settings: {
        fill: palette.c4,
        fill2: palette.c22
      },
      stop: {
        fill: palette.c4,
        fill2: palette.c5
      }
    }
  }
};

export const themeKuna = merge({}, baseTheme, theme);

