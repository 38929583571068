import merge from 'lodash/merge'
import { images as defaultImages } from 'merchants/identance/images'
import mobileAppQR from './mobile-app-qr.png';
import logo from './logo.svg';

export const images = merge({}, defaultImages,{
  logo,
  mobileAppQR
});

